import antdEN from "antd/lib/locale-provider/en_US";
import beMessages from "../locales/en_US/base.json";
import accMessages from "../locales/en_US/account.json";
import settingOrganization from "../locales/en_US/setting_organization.json";
import maccount from "../locales/en_US/maccount.json";
import account from "../locales/en_US/account.json";
import portfolio_transaction from "../locales/en_US/portfolio_transaction.json";
import profile from "../locales/en_US/profile.json"
import hedgefund_return from "../locales/en_US/hedgefund_return.json"
import analytical from "../locales/en_US/AnalyticalTool/base.json"
const EnLang = {
  messages: {
    ...beMessages,
    ...accMessages,
    ...settingOrganization,
    ...maccount,
    ...account,
    ...portfolio_transaction,
    ...profile,
    ...hedgefund_return,
    ...analytical
  },
  antd: antdEN,
  locale: 'en-US',
};
export default EnLang;
