/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
} from '../../../constants/StatusCode'
import { PortfoliosApi } from '../../../client-axios/api'
import { axiosInstance } from '../../../core/axios'
import { configHeader } from '../../../core/token'
import { Dispatch } from 'redux'
import {
  GET_BY_ID_PORTFOLIO_SUCCESS,
  GET_BY_ID_PORTFOLIO_ERROR,
  GET_BY_ID_PORTFOLIO,
  CREATE_PORTFOLIO_SUCCESS,
  CREATE_PORTFOLIO_ERROR,
  UPDATE_PORTFOLIO_ERROR,
  UPDATE_PORTFOLIO_SUCCESS,
  GET_PORTFOLIO_BY_ACCOUNT_ID_SUCCESS,
  UPDATE_PORTFOLIO,
  GET_LIST_PORTFOLIO,
  GET_LIST_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_HOLDING_SUCCESS,
  UPDATE_PORTFOLIO_HOLDING_ERROR,
  GET_PORTFOLIO_BY_ACCOUNT_ID_AT_SUCCESS,
} from '../../../constants/ActionTypes'
import { PortfolioItem } from '../../../dto'
import { message } from 'antd'
import { capitalizeFirstLetter } from '../../../util/function'
import { showMessage } from '../../../util/showMessage'

export const getListPortfolio = (): unknown => (dispatch: Dispatch) => {
  dispatch({ type: GET_LIST_PORTFOLIO })
  const portfoliosApi = new PortfoliosApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  portfoliosApi.findAllWithPortfolios().then((res) => {
    if (res?.status === STATUS_CODE_SUCCESS) {
      dispatch({ type: GET_LIST_PORTFOLIO_SUCCESS, data: res.data })
    } else {
      return res
    }
  })
}

export const createPortfolio =
  (payload: PortfolioItem): unknown =>
  (dispatch: Dispatch) => {
    const portfoliosApi = new PortfoliosApi(
      configHeader(),
      undefined,
      axiosInstance
    )

    return portfoliosApi
      .createWithPortfolios(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({ type: CREATE_PORTFOLIO_SUCCESS, payload: res.data })
          return res?.status
        }
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: CREATE_PORTFOLIO_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
        }
        return error?.response?.statusCode
      })
  }

export const updatePortfolio =
  (payload: { id: any; data: PortfolioItem }): unknown =>
  (dispatch: Dispatch) => {
    const portfoliosApi = new PortfoliosApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: UPDATE_PORTFOLIO })
    return portfoliosApi
      .updateWithPortfolios(payload.id, payload.data)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: UPDATE_PORTFOLIO_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: UPDATE_PORTFOLIO_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
        }
        return error?.response?.statusCode
      })
  }

export const getPortfolioById =
  (payload: { id: any }): unknown =>
  (dispatch: Dispatch) => {
    const portfoliosApi = new PortfoliosApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: GET_BY_ID_PORTFOLIO })
    return portfoliosApi
      .findOneWithPortfolios(payload.id)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_BY_ID_PORTFOLIO_SUCCESS, payload: res?.data })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_BY_ID_PORTFOLIO_ERROR,
          error: error.response?.data?.message,
        })
      })
  }

export const getPortfolioByAccountId =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const portfoliosApi = new PortfoliosApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return portfoliosApi
      .findAllByAccountIdWithPortfolios(payload.accountId)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: GET_PORTFOLIO_BY_ACCOUNT_ID_SUCCESS,
            data: res?.data,
          })
          dispatch({
            type: GET_PORTFOLIO_BY_ACCOUNT_ID_AT_SUCCESS,
            data: res?.data,
          })
        }
      })
  }
export const updateHoldings =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const portfoliosApi = new PortfoliosApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return portfoliosApi
      .updateHoldingWithPortfolios(payload.id, payload.data)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: UPDATE_PORTFOLIO_HOLDING_SUCCESS,
            payload: res?.data,
          })
          return res?.status
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PORTFOLIO_HOLDING_ERROR,
          error: error.response?.data?.message,
        })
      })
  }
