/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux'
import {
  GET_BY_ID_DIVISION,
  GET_BY_ID_DIVISION_SUCCESS,
  GET_BY_ID_DIVISION_ERROR,
  CREATE_DIVISION_SUCCESS,
  CREATE_DIVISION_ERROR,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_DIVISION_ERROR,
  GET_LIST_DIVISION_SUCCESS,
  GET_LIST_DIVISION_BY_INVESTORID_SUCCESS,
  GET_LIST_DIVISION_BY_INVESTORID_ERROR,
} from '../../../constants/ActionTypes'
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
} from '../../../constants/StatusCode'
import { DivisionsApi, InvestorsApi } from '../../../client-axios/api'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'
import { capitalizeFirstLetter } from '../../../util/function'
import { showMessage } from '../../../util/showMessage'
export const getListDivisionMaster = (): unknown => (dispatch: Dispatch) => {
  const divisionsApi = new DivisionsApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  return divisionsApi.findAllWithDivisions().then((res) => {
    if (res?.status === STATUS_CODE_SUCCESS) {
      dispatch({ type: GET_LIST_DIVISION_SUCCESS, data: res.data })
    }
  })
}

export const createDivision =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const divisionsApi = new DivisionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return divisionsApi
        .createWithDivisions(payload)
        .then((res) => {
          if (res.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({ type: CREATE_DIVISION_SUCCESS, payload: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          dispatch({
            type: CREATE_DIVISION_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          return error?.response?.statusCode
        })
    }
export const updateDivision =
  (payload: { id: any; data: any }): unknown =>
    (dispatch: Dispatch) => {
      const divisionsApi = new DivisionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return divisionsApi
        .updateWithDivisions(payload.id, payload.data)
        .then((res) => {
          if (res?.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({ type: UPDATE_DIVISION_SUCCESS, data: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_DIVISION_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          return error?.response?.statusCode
        })
    }
export const getDivisionById =
  (payload: { id: any }): unknown =>
    (dispatch: Dispatch) => {
      const divisionsApi = new DivisionsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      dispatch({ type: GET_BY_ID_DIVISION })
      return divisionsApi
        .findOneWithDivisions(payload.id)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({ type: GET_BY_ID_DIVISION_SUCCESS, payload: res?.data })
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_BY_ID_DIVISION_ERROR,
            error: error.response?.data?.message,
          })
        })
    }
export const getDivisionByInvestorId =
  (payload: { id: any }): unknown =>
    (dispatch: Dispatch) => {
      const investorsApi = new InvestorsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return investorsApi
        .findDivisionsByInvestorWithInvestors(payload.id)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({
              type: GET_LIST_DIVISION_BY_INVESTORID_SUCCESS,
              data: res?.data,
            })
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_LIST_DIVISION_BY_INVESTORID_ERROR,
            error: error.response?.data?.message,
          })
        })
    }
