import React, { useState } from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import { Menu, PageHeader } from 'antd'

// import Auxiliary from "util/Auxiliary";
import { toggleCollapsedSideNav } from '../../appRedux/actions'
//import SearchBox from "../../components/SearchBox";
import UserInfo from '../../components/UserInfo'
// import AppNotification from "../../components/AppNotification";
// import MailNotification from "../../components/MailNotification";
// import AppsNavigation from "../Sidebar/AppsNavigation";
import IntlMessages from '../../util/IntlMessages'
import { useHistory } from 'react-router-dom'
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { PAGE_NUMBER_PORT_TXN } from '../../constants/ActionTypes'
import { Breadcrumbs, Title } from '../../components/BreadCrumbHeader'
import { Header } from 'antd/lib/layout/layout'
import Auxiliary from '../../util/Auxiliary'

const Topbar = () => {
  const { navStyle } = useSelector((state: RootStateOrAny) => state.settings)
  const navCollapsed = useSelector(
    (state: RootStateOrAny) => state.common.navCollapsed
  )
  const width = useSelector((state: RootStateOrAny) => state.common.width)
  const dispatch = useDispatch()
  const history = useHistory()
  const [path, setPath] = useState<string>('')
  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub-popover">
  //       {languageData.map(language =>
  //         <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() =>
  //           dispatch(switchLanguage(language))
  //         }>
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       )}
  //     </ul>
  //   </CustomScrollbars>);
  // const updateSearchChatUser = (evt) => {
  //   setSearchText(evt.target.value);
  // };
  function countWords(text: string) {
    const arr = text.split('/')
    return arr.filter((word: string) => word !== '/').length
  }
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed))
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none-0 gx-pointer">
        <img alt="" src={'/assets/images/w-logo.png'} />
      </Link>
      {countWords(history.location.pathname) > 4 &&
      !history.location.pathname.includes('/analyze') ? (
        <PageHeader
          className="page-header"
          breadcrumb={<Breadcrumbs onData={(data) => setPath(data)} />}
          title={<Title />}
          onBack={() => {
            history.push(path)
          }}
        ></PageHeader>
      ) : countWords(history.location.pathname) > 4 &&
        history.location.pathname.includes('/analyze/hf') ? (
        <PageHeader
          className="page-header"
          breadcrumb={<Breadcrumbs onData={(data) => setPath(data)} />}
          title={<Title />}
          onBack={() => history.push(`/main/analyze/hfs`)}
        ></PageHeader>
      ) : countWords(history.location.pathname) > 4 &&
        history.location.pathname.includes('/analyze/acct') ? (
        <PageHeader
          className="page-header"
          breadcrumb={<Breadcrumbs onData={(data) => setPath(data)} />}
          title={<Title />}
          onBack={() => history.push(`/main/analyze/accts`)}
        ></PageHeader>
      ) : countWords(history.location.pathname) > 4 &&
        history.location.pathname.includes('/analyze/investor') ? (
        <PageHeader
          className="page-header"
          breadcrumb={<Breadcrumbs onData={(data) => setPath(data)} />}
          title={<Title />}
          onBack={() => history.push(`/main/analyze/investors`)}
        ></PageHeader>
      ) : countWords(history.location.pathname) > 4 &&
        history.location.pathname.includes('/roll-up') ? (
        <PageHeader
          className="page-header"
          breadcrumb={<Breadcrumbs onData={(data) => setPath(data)} />}
          title={<Title />}
          onBack={() => history.goBack()}
        ></PageHeader>
      ) : countWords(history.location.pathname) > 4 &&
        history.location.pathname.includes('/return') ? (
        <PageHeader
          className="page-header"
          breadcrumb={<Breadcrumbs onData={(data) => setPath(data)} />}
          title={<Title />}
          onBack={() => history.goBack()}
        ></PageHeader>
      ) : (
        <PageHeader
          className="page-header"
          breadcrumb={<Breadcrumbs onData={(data) => setPath(data)} />}
          title={<Title />}
        ></PageHeader>
      )}
      <ul className="gx-header-notifications gx-ml-auto" />
      <ul className="gx-header-notifications gx-ml-auto" />
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="header-link">
          {' '}
          <Link to="/main/analyze/hfs">
            <span>
              <IntlMessages id="head.hedgeFunds" />
            </span>
          </Link>
        </li>
        <li className="header-link">
          {' '}
          <Link to="/main/analyze/accts">
            {/* <i className="icon icon-home" /> */}
            <span>
              <IntlMessages id="head.accounts" />
            </span>
          </Link>
        </li>
        <li className="header-link">
          {' '}
          <Link to="/main/analyze/investors">
            {/* <i className="icon icon-home" /> */}
            <span>
              <IntlMessages id="head.investors" />
            </span>
          </Link>
        </li>
      </ul>

      <ul className="gx-header-notifications head-menu-custome mg-left-20">
        {width >= TAB_SIZE ? (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        ) : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  )
}

export default Topbar
