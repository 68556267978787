/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { AccountItem } from '../../dto'
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
  STATUS_CODE_UPDATE_SUCCESS,
  // STATUS_BAD_REQUEST,
} from '../../../constants/StatusCode'
import { Dispatch } from 'redux'
import { HedgeFundsApi } from '../../../client-axios/api'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'
import {
  GET_LIST_HEDGEFUND,
  GET_LIST_HEDGEFUND_ERROR,
  GET_LIST_HEDGEFUND_SUCCESS,
  GET_LIST_HEDGEFUND_DEFAULT,
  // GET_BY_ID_HEDGEFUND,
  GET_BY_ID_HEDGEFUND_SUCCESS,
  CREATE_HEDGEFUND_SUCCESS,
  CREATE_HEDGEFUND_ERROR,
  UPDATE_HEDGEFUND_SUCCESS,
  UPDATE_HEDGEFUND_ERROR,
  GET_ALL_CLASS_BY_HEDGEFUND_ID,
  GET_LIST_HEDGEFUND_ACTIVE,
} from '../../../constants/ActionTypes'
import { capitalizeFirstLetter } from '../../../util/function'
import { showMessage } from '../../../util/showMessage'
export const getListHedgeFund = () => (dispatch: Dispatch) => {
  const hedgefundsApi = new HedgeFundsApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  dispatch({ type: GET_LIST_HEDGEFUND })
  return hedgefundsApi.findAllWithHedgefunds().then(
    (res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_HEDGEFUND_SUCCESS, data: res.data })
        dispatch({ type: GET_LIST_HEDGEFUND_DEFAULT, data: res.data })
        dispatch({ type: GET_LIST_HEDGEFUND_ACTIVE, data: res.data })
      }
      return res.status
    })
    .catch((error) => {
      if (error.response) {
        dispatch({
          type: GET_LIST_HEDGEFUND_ERROR,
          error: error.response?.data?.detail?.message,
        })
        message.error(error.response?.data?.detail?.message)
      }
      return error?.response?.statusCode
    })
}
export const createHedgeFund =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const hedgefundsApi = new HedgeFundsApi(
        configHeader(),
        undefined,
        axiosInstance
      )

      return hedgefundsApi
        .createWithHedgefunds(payload)
        .then((res) => {
          if (res.status === STATUS_CODE_CREATE_SUCCESS) {
            dispatch({ type: CREATE_HEDGEFUND_SUCCESS, data: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: CREATE_HEDGEFUND_ERROR,
              error: error.response?.data?.detail?.message,
            })
            showMessage('error', capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          }
          return error?.response?.statusCode
        })
    }
export const updateHedgeFund =
  (payload: { id: any; data: any }): unknown =>
    (dispatch: Dispatch) => {
      const hedgefundsApi = new HedgeFundsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return hedgefundsApi
        .updateWithHedgefunds(payload.id, payload.data)
        .then((res) => {
          if (res?.status === STATUS_CODE_UPDATE_SUCCESS) {
            dispatch({ type: UPDATE_HEDGEFUND_SUCCESS, payload: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: UPDATE_HEDGEFUND_ERROR,
              error: error.response?.data?.detail?.message,
            })
            showMessage('error', capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          }
          return error?.response?.statusCode
        })
    }
export const getHedgeFundById =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const hedgefundsApi = new HedgeFundsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return hedgefundsApi.findOneWithHedgefunds(payload.id).then(
        (res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({ type: GET_BY_ID_HEDGEFUND_SUCCESS, payload: res.data })
          }
          return res.status
        },
      ).catch((error) => {
        if (error.response) {
          message.error(error.response?.data?.detail?.message)
        }
        return error?.response?.statusCode
      })
    }
export const getClassByHedgeFundId =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const hedgefundsApi = new HedgeFundsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return hedgefundsApi
        .findClassesByHedgeFundWithHedgefunds(payload.id)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({ type: GET_ALL_CLASS_BY_HEDGEFUND_ID, data: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          if (error.response) {
            return error.response
          }
        })
    }
export const getClassByHedgeFundForHoldingsId =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const hedgefundsApi = new HedgeFundsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return hedgefundsApi
        .findClassesByHedgeFundForHoldingSettingsWithHedgefunds(
          payload.id,
          payload.portfolioId
        )
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({ type: GET_ALL_CLASS_BY_HEDGEFUND_ID, data: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          if (error.response) {
            return error.response
          }
        })
    }
// export const actionSaveListHedgeFunds = (payload: any) => ({
//     type: SAVE_LIST_HEDGEFUND,
//     payload,
// })
