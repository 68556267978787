/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, Link } from 'react-router-dom'

import { hideMessage, showAuthLoader, userSignIn } from '../appRedux/actions'

import IntlMessages from '../util/IntlMessages'
import CircularProgress from '../components/CircularProgress'
const SignIn = () => {
  const lastLocation = useLocation()
  const dispatch = useDispatch()
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  )
  const history = useHistory()
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage())
      }, 50)
    }
    if (authUser !== null) {
      if (lastLocation?.state === undefined) {
        history.push('/')
      }
      if (
        lastLocation?.state?.from?.pathname !==
        ('/signup' || '/registerUser')
      ) {
        history.push(lastLocation?.state?.from?.pathname)
      }
    }
  })
  const onFinish = async (values) => {
    dispatch(showAuthLoader())
    dispatch(userSignIn(values))
  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature'/> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'The input is not valid E-mail!',
                    type: 'email',
                  },
                ]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: 'Please input your Password!' },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{' '}
                <Link to="/registerUser">
                  <IntlMessages id="app.userAuth.registerUser" />
                </Link>
              </Form.Item>
              <Link to="/forgotPassword">
                <IntlMessages id="app.userAuth.forgotPassword" />
              </Link>
              <div className="gx-flex-row gx-justify-content-between">
                {/* <span>or connect with</span> */}
                <ul className="gx-social-link"></ul>
              </div>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  )
}

export default SignIn
