import { Dispatch } from 'redux'
import {
  GET_LIST_HEDGE_FUND_RETURN_SUCCESS,
  GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY_SUCCESS,
  GET_LIST_HEDGE_FUND_RETURN,
  GET_LIST_HEDGE_FUND_RETURN_DEFAULT_SUCCESS,
  CREATE_INPUT_RETURN_SUCCESS,
  GET_LIST_HF_HOLDING_LEVEL,
  GET_LIST_HF_HOLDING_LEVEL_SUCCESS,
  BULK_SAVE_INPUT_RETURN_SUCCESS,
  BULK_SAVE_INPUT_RETURN_ERROR,
  GET_VALUE_BASE_DATE_HF_RETURN,
  GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY,
} from '../../../constants/ActionTypes'
import {
  STATUS_BAD_REQUEST,
  STATUS_CODE_CREATE_SUCCESS,
  STATUS_CODE_SUCCESS,
} from '../../../constants/StatusCode'
import {
  CreateInputReturnDto,
  InputReturnApi,
  UpdateInputReturnDto,
} from '../../../client-axios/api'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'
import { capitalizeFirstLetter } from '../../../util/function'
import { showMessage } from '../../../util/showMessage'
export const getListInputReturn =
  (payload: { basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    dispatch({ type: GET_LIST_HEDGE_FUND_RETURN })
    const inputReturn = new InputReturnApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return inputReturn.listWithInputReturn(payload.basedate).then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({
          type: GET_LIST_HEDGE_FUND_RETURN_SUCCESS,
          HFReturnList: res.data,
        })
      }
    })
  }
export const countByHedgeFund =
  (payload: { basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    const inputReturn = new InputReturnApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: GET_LIST_HEDGE_FUND_RETURN })
    return inputReturn
      .countByHedgefundWithInputReturn(payload.basedate)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: GET_LIST_HEDGE_FUND_RETURN_SUCCESS,
            data: res.data,
          })
          dispatch({
            type: GET_LIST_HEDGE_FUND_RETURN_DEFAULT_SUCCESS,
            data: res.data,
          })
        }
      })
  }
export const getListWithBaseDateAndId =
  (payload: { fundId: number; basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    const inputReturn = new InputReturnApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({
      type: GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY,
    })
    return inputReturn
      .findByFundAndBasedateWithInputReturn(payload.fundId, payload.basedate)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY_SUCCESS,
            dataInputReturn: res.data,
          })
        }
      })
  }
export const createInputReturn =
  (payload: CreateInputReturnDto[]): unknown =>
  (dispatch: Dispatch) => {
    const inputReturn = new InputReturnApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return inputReturn
      .createWithInputReturn(payload)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: CREATE_INPUT_RETURN_SUCCESS,
            data: res.data,
          })
          return res?.data
        }
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          dispatch({
            type: CREATE_INPUT_RETURN_SUCCESS,
            data: error.data,
          })
        }
        showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
        return error?.response?.statusCode
      })
  }

export const bulkSaveInputReturn =
  (payload: UpdateInputReturnDto[]): unknown =>
  (dispatch: Dispatch) => {
    const inputReturn = new InputReturnApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return inputReturn
      .bulkSaveWithInputReturn(payload)
      .then((res) => {
        if (res?.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({
            type: BULK_SAVE_INPUT_RETURN_SUCCESS,
            recordNewData: res.data,
          })
          return res?.status
        }
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          dispatch({
            type: BULK_SAVE_INPUT_RETURN_ERROR,
            data: error.data,
          })
        }
        showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
        return error?.response?.statusCode
      })
  }
export const getListHFHolding =
  (payload: { fundId: number; basedate: string }): unknown =>
  (dispatch: Dispatch) => {
    const inputReturn = new InputReturnApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: GET_LIST_HF_HOLDING_LEVEL })
    return inputReturn
      .findHoldingsByFundAndBasedateWithInputReturn(
        payload.fundId,
        payload.basedate
      )
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: GET_LIST_HF_HOLDING_LEVEL_SUCCESS,
            dataHolding: res.data,
          })
        }
      })
  }
export const getValueBasedateReturn = (basedate: string | null): unknown => {
  return {
    type: GET_VALUE_BASE_DATE_HF_RETURN,
    valueBasedate: basedate,
  }
}
