/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from 'antd'
import Amplify, { Auth } from 'aws-amplify'
import { Dispatch } from 'redux'
import {
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_SEND_CODE,
  HIDE_MESSAGE,
  INIT_URL,
  LINK_SIGNIN_FALSE,
  LINK_SIGNIN_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SEND_CODE_SUCCESS,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
} from '../../constants/ActionTypes'
// eslint-disable-next-line @typescript-eslint/no-var-requires
import AmplifyConfig from '../../util/aws-exports'
import { createBrowserHistory } from 'history'
import { push } from 'connected-react-router'
import { BehaviorSubject } from 'rxjs'
import { getProfileUser } from './User'
import { showMessage } from '../../util/showMessage'

export const browserHistory = createBrowserHistory()
Amplify.configure(AmplifyConfig)
export const congitoUser = new BehaviorSubject(null)

export const userSignUp =
  (userRegister: {
    location: string
    name: string
    email: string
    password: any
    company: string
  }) =>
  async (dispatch: (arg0: { type: any; payload?: any }) => void) => {
    try {
      await Auth.signUp({
        username: userRegister.email,
        password: userRegister.password,
        attributes: {
          email: userRegister.email,
          name: userRegister.name,
          company: userRegister.company,
          location: userRegister.location,
        },
      })
      message.success(SIGNUP_USER)
      dispatch(hideAuthLoader())
      return true
    } catch (error: any) {
      showMessage('error', error.message)
      dispatch(hideAuthLoader())
      return false
    }
  }
export const userSignIn =
  (userLogin: { email: any; password: any }): unknown =>
  (dispatch: Dispatch) => {
    return Auth.signIn({
      username: userLogin.email,
      password: userLogin.password,
    })
      .then((user) => {
        localStorage.setItem('username', user?.attributes?.sub)
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          congitoUser.next(user)
          dispatch(hideAuthLoader())
          dispatch(push('/change-password?email=' + userLogin.email))
        } else {
          Auth.currentSession().then((res) => {
            const idToken = res?.getIdToken()
            const jwt = idToken.getJwtToken()
            return dispatch(userSignInStore(jwt))
          })
          return message.success(SIGNIN_USER)
        }
      })

      .catch((error: any) => {
        showMessage('error', error.message)
        dispatch(hideAuthLoader())
      })
  }
export const userCompleteNewPassword =
  (userLogin: { user: string; password: string }): unknown =>
  async (dispatch: Dispatch) => {
    try {
      congitoUser.subscribe((value: any) => {
        Auth.completeNewPassword(value, userLogin.password, {
          name: value.username,
        })
          .then(async (res) => {
            if(res?.username) {
              getProfileUser({sub: res.username});
            }
            dispatch(push('/signin'))
            dispatch(hideAuthLoader())
          })
          .catch((error) => {
            showMessage('error', error.message)
          })
      })
    } catch (error: any) {
      message.error(error.message)
    }
  }
export const userSignOut =
  () => async (dispatch: (arg0: { type: any; payload: any }) => void) => {
    try {
      await Auth.signOut()
      dispatch(userSignOutStore(undefined))
      return message.success(SIGNOUT_USER)
    } catch (error) {
      message.error((error as any)?.message as string);
    }
  }
export const forgotPassword =
  (Username: string) => async (dispatch: Dispatch) => {
    try {
      await Auth.forgotPassword(Username)
      dispatch(sendCodeSuccess())
      message.success(FORGOT_PASSWORD_SEND_CODE)
      return true
    } catch (error) {
      showMessage('error', (error as any)?.message as string)
    }
  }
export const forgotPasswordSubmit =
  (Username: string, code: string, newPassword: string) =>
  async (dispatch: Dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(Username, code, newPassword)
      dispatch(linkSignInSuccess())
      message.success(CHANGE_PASSWORD_SUCCESS)
      return true
    } catch (error: any) {
      dispatch(linkSignInFalse())
      showMessage('error', error.message)
    }
  }
export const userSignUpSuccess = (authUser: any) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  }
}

export const userSignInSuccess = (authUser: any) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  }
}
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
}
export const sendCodeSuccess = () => {
  return {
    type: SEND_CODE_SUCCESS,
  }
}
export const showAuthMessage = (message: any) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  }
}

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER,
  }
}
export const userGoogleSignInSuccess = (authUser: any) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  }
}
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER,
  }
}
export const userFacebookSignInSuccess = (authUser: any) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  }
}
export const setInitUrl = (url: any) => {
  return {
    type: INIT_URL,
    payload: url,
  }
}
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  }
}
export const userTwitterSignInSuccess = (authUser: any) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  }
}
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  }
}
export const userGithubSignInSuccess = (authUser: any) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  }
}
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  }
}

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  }
}
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  }
}
export const linkSignInSuccess = () => {
  return {
    type: LINK_SIGNIN_SUCCESS,
  }
}
export const linkSignInFalse = () => {
  return {
    type: LINK_SIGNIN_FALSE,
  }
}

const userSignInStore = (signInUser: string) => {
  localStorage.setItem('token', signInUser)
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: signInUser,
  }
}
const userSignOutStore = (signOutUser: undefined) => {
  localStorage.removeItem('token')
  return {
    type: SIGNOUT_USER_SUCCESS,
    payload: signOutUser,
  }
}
